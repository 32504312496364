











































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { ContratoService, MedicaoService, ReceptorService, TransportadoraService } from '@/core/services/residuo';
import { Cliente } from '@/core/models/geral';
import { ClienteService } from '@/core/services/geral';
import { Contrato, Receptor } from '@/core/models/residuo';

@Component
export default class ConfiguracaoRelatorioProvisionamento extends PageBase {
    service: MedicaoService = new MedicaoService();
    
    titulosReceitaTransporte: any[] = [];
    dadosReceitaTransporte: any[] = [];
    loadingReceitaTransporte: boolean = true;

    titulosGastoTransporte: any[] = [];
    dadosGastoTransporte: any[] = [];
    loadingGastoTransporte: boolean = true;

    titulosReceptor: any[] = [];
    dadosReceptor: any[] = [];
    loadingReceptor: boolean = true;

    titulosReceptorTecnologia: any[] = [];
    dadosReceptorTecnologia: any[] = [];
    loadingReceptorTecnologia: boolean = true;

    titulosGastoLocatario: any[] = [];
    dadosGastoLocatario: any[] = [];
    loadingGastoLocatario: boolean = true;

    titulosGastoLocatarioGrupo: any[] = [];
    dadosGastoLocatarioGrupo: any[] = [];
    loadingGastoLocatarioGrupo: boolean = true;

    filtro: any = {
        dataInicio: null,
        dataFim: null,
        clienteId: null,
        contratoId: null,
        osNumero: null,
        odNumero: null,
        receptorId: null,
        transportadorId: null
    }

    clienteService: ClienteService = new ClienteService();
    clientes: Cliente[] = [];
    onSearchCliente: any = null;
    isClienteLoading: boolean = false;

    contratoService: ContratoService = new ContratoService();
    contratos: Contrato[] = [];
    onSearchContrato: any = null;
    isContratoLoading: boolean = false;

    receptorService: ReceptorService = new ReceptorService();
    receptores: Receptor[] = [];
    onSearchReceptor: any = null;
    isReceptorLoading: boolean = false;

    transportadorService: TransportadoraService = new TransportadoraService();
    transportadores: Receptor[] = [];
    onSearchTransportador: any = null;
    isTransportadorLoading: boolean = false;

    @Watch("onSearchCliente")
    WatchCliente(val: string){
        if(this.filtro.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true;        
        this.clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        ).finally(() => {
            this.isClienteLoading = false;
        });
    }

    @Watch("onSearchContrato")
    WatchContrato(val: string){
        if(this.filtro.contratoId) return;
        if (this.isContratoLoading) return;
        if (!val) return;
        this.isContratoLoading = true;        
        this.contratoService.AutoComplete(val).then(
            res => {
                this.contratos = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        ).finally(() => {
            this.isContratoLoading = false;
        });
    }

    @Watch("onSearchReceptor")
    WatchReceptor(val: string){
        if(this.filtro.receptorId) return;
        if (this.isReceptorLoading) return;
        if (!val) return;
        this.isReceptorLoading = true;        
        this.receptorService.AutoComplete(val).then(
            res => {
                this.receptores = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        ).finally(() => {
            this.isReceptorLoading = false;
        });
    }

    @Watch("onSearchTransportador")
    WatchTransportador(val: string){
        if(this.filtro.transportadorId) return;
        if (this.isTransportadorLoading) return;
        if (!val) return;
        this.isTransportadorLoading = true;        
        this.transportadorService.AutoComplete(val).then(
            res => {
                this.transportadores = res.data;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        ).finally(() => {
            this.isTransportadorLoading = false;
        });
    }

    mounted(){
        var data = new Date();
        this.filtro.dataInicio = new Date(data.getFullYear(), data.getMonth(), 1).toYYYYMMDD();
        this.filtro.dataFim = new Date(data.getFullYear(), data.getMonth() + 1, 0).toYYYYMMDD();
        this.Carregar();
    }

    Carregar(){
        this.ReceitaTransporte();
        this.GastoTransporte();
        this.GastoReceptor();
        this.GastoReceptorTecnologia();
        this.GastoLocatario();
        this.GastoLocatarioGrupo();
    }

    ReceitaTransporte(){
        this.loadingReceitaTransporte = true;
        this.service.PrevisaoReceitaTransporte(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.odNumero, this.filtro.receptorId, this.filtro.transportadorId).then(
        res => {
            this.titulosReceitaTransporte = res.data.titulos;
            this.dadosReceitaTransporte = res.data.dados;
        },
        err => {
            this.$swal('Aviso', err.message, 'error')
        }
        ).finally(() => {
            this.loadingReceitaTransporte = false;
        })
    }

    GastoTransporte(){
        this.loadingGastoTransporte = true;
        this.service.PrevisaoGastoTransporte(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.odNumero, this.filtro.receptorId, this.filtro.transportadorId).then(
        res => {
            this.titulosGastoTransporte = res.data.titulos;
            this.dadosGastoTransporte = res.data.dados;
        },
        err => {
            this.$swal('Aviso', err.message, 'error')
        }
        ).finally(() => {
            this.loadingGastoTransporte = false;
        })
    }

    DetalharRelacaoGastoTransporte(){
        let routeLink = this.$router.resolve({name: "detalharGastoTransporte",
            query:{
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim,
                clienteId: this.filtro.clienteId,
                contratoId: this.filtro.contratoId,
                osNumero: this.filtro.osNumero,
                odNumero: this.filtro.odNumero,
                receptorId: this.filtro.receptorId,
                transportadorId: this.filtro.transportadorId
            }
        });
        window.open(routeLink.href, '_blank');
    }

    GastoReceptor(){
        this.loadingReceptor = true;
        this.service.PrevisaoGastoReceptor(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.odNumero, this.filtro.receptorId, this.filtro.transportadorId).then(
            res => {
                this.titulosReceptor = res.data.titulos;
                this.dadosReceptor = res.data.dados;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        ).finally(() => {
            this.loadingReceptor = false;
        })
    }

    DetalharRelacaoGastoReceptor(){
        let routeLink = this.$router.resolve({name: "detalharGastoReceptor",
            query:{
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim,
                clienteId: this.filtro.clienteId,
                contratoId: this.filtro.contratoId,
                osNumero: this.filtro.osNumero,
                odNumero: this.filtro.odNumero,
                receptorId: this.filtro.receptorId,
                transportadorId: this.filtro.transportadorId
            }
        });
        window.open(routeLink.href, '_blank');
    }

    GastoReceptorTecnologia(){
        this.loadingReceptorTecnologia = true;
        this.service.PrevisaoGastoReceptorTecnologia(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.odNumero, this.filtro.receptorId, this.filtro.transportadorId).then(
        res => {
            this.titulosReceptorTecnologia = res.data.titulos;
            this.dadosReceptorTecnologia = res.data.dados;
        },
        err => {
            this.$swal('Aviso', err.message, 'error')
        }
        ).finally(() => {
            this.loadingReceptorTecnologia = false;
        })
    }

    DetalharRelacaoGastoReceptorTecnologia(){
        let routeLink = this.$router.resolve({name: "detalharGastoReceptorTecnologia",
            query:{
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim,
                clienteId: this.filtro.clienteId,
                contratoId: this.filtro.contratoId,
                osNumero: this.filtro.osNumero,
                odNumero: this.filtro.odNumero,
                receptorId: this.filtro.receptorId,
                transportadorId: this.filtro.transportadorId
            }
        });
        window.open(routeLink.href, '_blank');
    }

    GastoLocatario(){
        this.loadingGastoLocatario = true;
        this.service.PrevisaoGastoLocatario(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.transportadorId).then(
        res => {
            this.titulosGastoLocatario = res.data.titulos;
            this.dadosGastoLocatario = res.data.dados;
        },
        err => {
            this.$swal('Aviso', err.message, 'error')
        }
        ).finally(() => {
            this.loadingGastoLocatario = false;
        })
    }

    DetalharRelacaoGastoLocatario(){
        let routeLink = this.$router.resolve({name: "detalharGastoLocatario",
            query:{
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim,
                clienteId: this.filtro.clienteId,
                contratoId: this.filtro.contratoId,
                osNumero: this.filtro.osNumero,
                transportadorId: this.filtro.transportadorId
            }
        });
        window.open(routeLink.href, '_blank');
    }

    GastoLocatarioGrupo(){
        this.loadingGastoLocatarioGrupo = true;
        this.service.PrevisaoGastoLocatarioGrupo(this.filtro.dataInicio, this.filtro.dataFim, this.filtro.clienteId, this.filtro.contratoId, this.filtro.osNumero, this.filtro.transportadorId).then(
            res => {
                this.titulosGastoLocatarioGrupo = res.data.titulos;
                this.dadosGastoLocatarioGrupo = res.data.dados;
            },
            err => {
                this.$swal('Aviso', err.message, 'error')
            }
        ).finally(() => {
            this.loadingGastoLocatarioGrupo = false;
        })
    }

    DetalharRelacaoGastoLocatarioGrupo(){
        let routeLink = this.$router.resolve({name: "detalharGastoLocatarioGrupo",
            query:{
                dataInicio: this.filtro.dataInicio,
                dataFim: this.filtro.dataFim,
                clienteId: this.filtro.clienteId,
                contratoId: this.filtro.contratoId,
                osNumero: this.filtro.osNumero,
                transportadorId: this.filtro.transportadorId
            }
        });
        window.open(routeLink.href, '_blank');
    }

    LimparFiltros(){
        var data = new Date();        
        this.filtro.dataInicio = new Date(data.getFullYear(), data.getMonth(), 1).toYYYYMMDD();
        this.filtro.dataFim = new Date(data.getFullYear(), data.getMonth() + 1, 0).toYYYYMMDD();
        this.Carregar();
    }
}  

